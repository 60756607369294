// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Cx = require("rescript-classnames/src/Cx.bs.js");
var $$Image = require("../Image/Image.bs.js");
var React = require("react");
var ReactIntl = require("react-intl");
var WelcomePageComponents = require("../WelcomePageComponents/WelcomePageComponents.bs.js");

function HiCheeDifferent$TextSection(props) {
  return React.createElement("div", {
              className: "tw-flex tw-flex-col tw-gap-16 tw-text-center md:tw-text-left tw-max-w-[350px]"
            }, React.createElement(WelcomePageComponents.H4.make, {
                  children: props.heading,
                  fontWeight: "SemiBold"
                }), React.createElement(WelcomePageComponents.P.make, {
                  children: props.text
                }));
}

var TextSection = {
  make: HiCheeDifferent$TextSection
};

function HiCheeDifferent$Section(props) {
  var flexDirection = props.flexDirection;
  var flexDirection$1 = flexDirection !== undefined ? flexDirection : /* Normal */1;
  return React.createElement("div", {
              className: Cx.cx([
                    "tw-flex md:tw-flex-row tw-justify-between tw-items-center tw-gap-[30px] tw-py-[64px] md:tw-py-[80px] lg:tw-py-[60px]",
                    flexDirection$1 ? "tw-flex-col" : "tw-flex-col-reverse"
                  ])
            }, props.children);
}

var Section = {
  make: HiCheeDifferent$Section
};

function HiCheeDifferent(props) {
  return React.createElement(React.Fragment, undefined, React.createElement(HiCheeDifferent$Section, {
                  children: null,
                  flexDirection: /* Reverse */0
                }, React.createElement($$Image.make, {
                      className: "tw-w-[343px] md:tw-w-[350px] tw-h-full tw-object-cover",
                      url: "images/static/welcome/search-by-listing-id.png",
                      fetchWidth: 1134,
                      fetchHeight: 933,
                      alt: "Search by Listing URL or ID",
                      format: /* WebP */2,
                      loading: /* Lazy */0
                    }), React.createElement(HiCheeDifferent$TextSection, {
                      heading: React.createElement(ReactIntl.FormattedMessage, {
                            id: "hichee-different.search-by-listing-id.heading",
                            defaultMessage: "Search by Listing ID or URL.{br}Compare Prices and Reviews",
                            values: {
                              br: React.createElement("br", undefined)
                            }
                          }),
                      text: React.createElement(ReactIntl.FormattedMessage, {
                            id: "hichee-different.search-by-listing-id.text",
                            defaultMessage: "Paste the listing URL or ID from Airbnb, Booking, or Vrbo to get the best prices and reviews on one page."
                          })
                    })), React.createElement(HiCheeDifferent$Section, {
                  children: null
                }, React.createElement(HiCheeDifferent$TextSection, {
                      heading: React.createElement(ReactIntl.FormattedMessage, {
                            id: "hichee-different.travel-hack-magic-search.heading",
                            defaultMessage: "Travel Hack: Magic Search"
                          }),
                      text: React.createElement(ReactIntl.FormattedMessage, {
                            id: "hichee-different.travel-hack-magic-search.text",
                            defaultMessage: "When direct booking is not available on HiChee, our Magic Search uses property images to help you look for the host or owners website on Google."
                          })
                    }), React.createElement($$Image.make, {
                      className: "tw-w-[343px] lg:tw-w-[362px] tw-h-full tw-object-cover",
                      url: "images/static/welcome/travel-hack-magic-search.png",
                      fetchWidth: 1086,
                      fetchHeight: 1023,
                      alt: "Travel Hack: Magic Search",
                      format: /* WebP */2,
                      loading: /* Lazy */0
                    })), React.createElement(HiCheeDifferent$Section, {
                  children: null,
                  flexDirection: /* Reverse */0
                }, React.createElement($$Image.make, {
                      className: "tw-w-[343px] lg:tw-w-[362px] tw-h-full tw-object-cover",
                      url: "images/static/welcome/book-directly-with-confidence.png",
                      fetchWidth: 1086,
                      fetchHeight: 1023,
                      alt: "Book Directly with Confidence",
                      format: /* WebP */2,
                      loading: /* Lazy */0
                    }), React.createElement(HiCheeDifferent$TextSection, {
                      heading: React.createElement(ReactIntl.FormattedMessage, {
                            id: "hichee-different.book-directly-with-confidence.heading",
                            defaultMessage: "Book Directly with Confidence"
                          }),
                      text: React.createElement(ReactIntl.FormattedMessage, {
                            id: "hichee-different.book-directly-with-confidence.text",
                            defaultMessage: "More and more Hosts add Direct Booking to Hichee, enabling everybody to negotiate rentals without platform fees. When hosts are unlisted, you can still try our Magic Search feature."
                          })
                    })));
}

var make = HiCheeDifferent;

var $$default = HiCheeDifferent;

exports.TextSection = TextSection;
exports.Section = Section;
exports.make = make;
exports.$$default = $$default;
exports.default = $$default;
exports.__esModule = true;
/* Image Not a pure module */
