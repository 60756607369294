// @flow
import React from 'react';

import { buildImageUrlFromJs as buildImageUrl } from 'app/rescript/libs/AwsImageHandlerV5.bs.js';

import { FormattedMessage } from 'react-intl';
import { Button } from 'app/styleguide/components';
import { newsroom as newsroomUrl, fans as fansUrl } from 'app/rescript/Route.bs.js';
import A from 'app/rescript/styleguide/components/Link/A__JsBridge.bs.js';

const TRUST_PARTNERS = [
  {
    url:
      'https://www.entrepreneur.com/starting-a-business/he-built-a-platform-to-find-you-the-best-vacation-rental/477832',
    imgHref: 'images/static/welcome/entrepreneur_logo.webp',
    imgWidth: 133,
    imgHeight: 50,
    smallScreenImgWidth: 106,
    smallScreenImgHeight: 40,
    alt: 'Entrepreneur',
    colorful: true,
  },
  {
    url: 'https://www.foxnews.com/tech/how-compare-vacation-rental-home-prices',
    imgHref: 'images/static/welcome/foxnewslogo_homepage.png',
    imgWidth: 40,
    imgHeight: 40,
    smallScreenImgWidth: 40,
    smallScreenImgHeight: 40,
    alt: 'Fox News',
  },
  {
    url: 'https://www.cardrates.com/news/hichee-can-save-travelers-both-time-and-money/',
    imgHref: 'images/static/welcome/card_rates_colorful_logo.webp',
    imgWidth: 86,
    imgHeight: 40,
    smallScreenImgWidth: 86,
    smallScreenImgHeight: 40,
    alt: 'Card Rates',
    colorful: true,
  },
  {
    url: 'https://cyberguy.com/travel/how-to-compare-vacation-rental-home-prices/',
    imgHref: 'images/static/welcome/cyberguy_gray_logo.webp',
    imgWidth: 50,
    imgHeight: 40,
    smallScreenImgWidth: 50,
    smallScreenImgHeight: 40,
    alt: 'Cyber Guy',
    colorful: true,
  },
  {
    url:
      'https://www.elliott.org/answers/how-to-rent-vacation-home-ultimate-guide/#h-how-do-i-find-and-rent-a-vacation-home',
    imgHref: 'images/static/welcome/elliott_report.webp',
    imgWidth: 83,
    imgHeight: 40,
    smallScreenImgWidth: 83,
    smallScreenImgHeight: 40,
    alt: 'Elliott Report',
  },
  {
    url: 'https://ktla.com/news/travel-sites-to-take-your-trip-to-the-next-level/',
    imgHref: 'images/static/welcome/ktla5_gray_logo.webp',
    imgWidth: 93,
    imgHeight: 35,
    smallScreenImgWidth: 93,
    smallScreenImgHeight: 35,
    alt: 'KTLA5',
    colorful: true,
  },
];

const TrustBanner = () => (
  <div
    id="trust-banner"
    className="tw-flex tw-flex-col tw-gap-[40px] tw-pb-[40px] lg:tw-pb-[80px] tw-items-center tw-px-16 md:tw-px-0"
  >
    <div className="tw-font-semibold tw-text-[21px] tw-leading-[32px] tw-text-neutral-gray md:tw-text-[24px] md:tw-text-leading-[36px] lg:tw-text-[32px] lg:tw-leading-[42px]">
      <FormattedMessage id="trust-banner.title" defaultMessage="HiChee in the News" />
    </div>
    <div className="tw-flex tw-flex-wrap tw-justify-between tw-items-center tw-w-full">
      {TRUST_PARTNERS.map(partner => (
        <div className="tw-p-10" key={partner.alt}>
          <picture>
            <source
              srcSet={`
                    ${buildImageUrl({
                      url: partner.imgHref,
                      width: partner.imgWidth,
                      height: partner.imgHeight,
                    })},
                    ${buildImageUrl({
                      url: partner.imgHref,
                      width: partner.imgWidth * 2,
                      height: partner.imgHeight * 2,
                    })} 2x,
                    ${buildImageUrl({
                      url: partner.imgHref,
                      width: partner.imgWidth * 3,
                      height: partner.imgHeight * 3,
                    })} 3x,
                  `}
              media="(min-width: 1024px)"
            />
            <source
              srcSet={`
                    ${buildImageUrl({
                      url: partner.imgHref,
                      width: partner.smallScreenImgWidth,
                      height: partner.smallScreenImgHeight,
                    })},
                    ${buildImageUrl({
                      url: partner.imgHref,
                      width: partner.smallScreenImgWidth * 2,
                      height: partner.smallScreenImgHeight * 2,
                    })} 2x,
                    ${buildImageUrl({
                      url: partner.imgHref,
                      width: partner.smallScreenImgWidth * 3,
                      height: partner.smallScreenImgHeight * 3,
                    })} 3x,
                  `}
            />
            <img src={buildImageUrl({ url: partner.imgHref })} alt={partner.alt} loading="lazy" />
          </picture>
        </div>
      ))}
    </div>
    <div className="tw-flex tw-gap-[40px] tw-flex-wrap tw-justify-center">
      <Button
        className="tw-static tw-m-0"
        asNativeLink
        href={newsroomUrl}
        size="md"
        color="black"
        marginMobile="bottom"
      >
        <FormattedMessage id="trust-banner.button" defaultMessage="More Mentions" />
      </Button>
      <A href={fansUrl} linkStyle="action" inline>
        <FormattedMessage id="trust-banner.fans-button" defaultMessage="See Our Fans' Posts" />
      </A>
    </div>
  </div>
);

export default TrustBanner;
