// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var A = require("./A.bs.js");
var Link = require("./Link.bs.js");
var React = require("react");

function fromString(rel, target) {
  if (target === "self") {
    return /* Self */0;
  }
  if (rel === undefined) {
    if (target === "_blank") {
      return /* Blank */{
              rel: undefined
            };
    } else {
      return /* Self */0;
    }
  }
  switch (rel) {
    case "noopener" :
        if (target === "_blank") {
          return /* Blank */{
                  rel: {
                    referrer: true,
                    follow: true
                  }
                };
        } else {
          return /* Self */0;
        }
    case "noopener nofollow" :
        if (target === "_blank") {
          return /* Blank */{
                  rel: {
                    referrer: true,
                    follow: false
                  }
                };
        } else {
          return /* Self */0;
        }
    case "noopener noreferrer" :
        if (target === "_blank") {
          return /* Blank */{
                  rel: {
                    referrer: false,
                    follow: true
                  }
                };
        } else {
          return /* Self */0;
        }
    case "noopener noreferrer nofollow" :
        if (target === "_blank") {
          return /* Blank */{
                  rel: {
                    referrer: false,
                    follow: false
                  }
                };
        } else {
          return /* Self */0;
        }
    default:
      return /* Self */0;
  }
}

var Target = {
  fromString: fromString
};

function A__JsBridge$default(props) {
  var inline = props.inline;
  var className = props.className;
  var target = props.target;
  var target$1 = target !== undefined ? target : "self";
  var className$1 = className !== undefined ? className : "";
  var inline$1 = inline !== undefined ? inline : false;
  return React.createElement(A.make, {
              href: props.href,
              target: fromString(props.rel, target$1),
              style: Link.Style.fromString(props.linkStyle),
              className: className$1,
              inline: inline$1,
              onClick: props.onClick,
              itemProp: props.itemProp,
              testId: props.testId,
              children: props.children
            });
}

var $$default = A__JsBridge$default;

exports.Target = Target;
exports.$$default = $$default;
exports.default = $$default;
exports.__esModule = true;
/* A Not a pure module */
